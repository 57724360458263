<template>
    <textarea :value="value" :v-model="value" :style="style" :placeholder="placeholder" @input="update($event.target.value)">afd</textarea>
</template>

<script>
export default {
    props: {
        name: String,
        value: String,
        placeholder: String,
        style: null
    },
    methods: {
        update: function (value) {
            this.$emit('update', value)
        }
    }
}
</script>

<style scoped lang="scss">
textarea {
    border:1px solid var(--layout-border-color); 
    padding:10px 10px;
    box-sizing:border-box;
    line-height:20px;
    height:150px;
    width:100%;
    resize:vertical;
    font-family:'Roboto';
    font-size:16px;
}
</style>
